<template>
  <!-- Variant Selection -->
  <base-form ref="formRef" :form validate-on="submit">
    <product-variant-selection
      v-model="attributeSelection"
      v-bind="{
        product,
        pending,
        attributes,
        attributeSelectionLabels,
        getAttrOptionAvailable,
        // OTW
        productInventory,
        showNotifyMe: false,
        variants: product.variants,
      }"
      show-badge
      color-swatch="rectangle"
      @select-color="(id, url, optionLabel) => $emit('selectCardStyle', id, url, optionLabel)"
      @select-size="(type, value, label) => $emit('selectCardAmount', type, value, label)"
    />
    <div class="mt-2">
      <vf-form-field
        v-slot="{ attrs }"
        name="amount"
        :rule="!attributeSelection.size ? [
          validateMin(customAmountRange.minAmount, replaceAll($t.giftCardMinAmount, { amount: minAmount })),
          validateMax(customAmountRange.maxAmount, replaceAll($t.giftCardMaxAmount, { amount: maxAmount })),
        ] : []"
      >
        <input-price v-model="form.amount" v-bind="attrs" :currency="product?.currency" />
        <p v-if="!attrs.invalid" class="b-b b-grey-70 pb-4 text-sm c-grey-20">
          {{ replaceAll($t.giftCardAmountMustBe, { amount: minAmount }) }}
        </p>
      </vf-form-field>
      <!-- Recipent details -->
      <div class="mb-2 mt-4 flex items-center space-x-2">
        <div class="h-4 w-4 flex justify-center rounded-full bg-grey-10">
          <span class="text-xs c-white fw-bold">3</span>
        </div>
        <p class="text-sm fw-bold">
          {{ $t.recipientDetails }}:
        </p>
      </div>
      <vf-form-field
        v-slot="{ attrs }"
        name="fullName"
        :rule="[
          validateRequired($t.recipientsName),
          validateName(),
          validateMinLength(2),
          validateMaxLength(60),
        ]"
      >
        <vf-input v-model="form.fullName" v-bind="attrs" required>
          {{ $t.recipientsName }}
        </vf-input>
      </vf-form-field>
      <vf-form-field
        v-slot="{ attrs }"
        name="email"
        :rule="[validateRequired($t.recipientsEmail), validateEmail()]"
        :hint="$t.emailFormatHint"
      >
        <vf-input v-model="form.email" v-bind="attrs" type="email" required class="mt-2">
          {{ $t.recipientsEmail }}
        </vf-input>
      </vf-form-field>
      <vf-form-field
        v-slot="{ attrs }"
        name="message"
        :rule="messageMaxLength ? [validateMaxLength(messageMaxLength)] : []"
        :hint="messageMaxLength ? replaceAll($t.maxCharacters, { max: messageMaxLength }) : undefined"
      >
        <div class="mt-4 b-t b-grey-70 pt-4 space-y-2">
          <p class="text-sm fw-bold">
            {{ $t.optionalMessage }}:
          </p>
          <vf-textarea
            v-model="form.message"
            v-bind="attrs"
            :props-textarea="{ maxlength: messageMaxLength }"
            resizable
          >
            <span class="sr-only">{{ $t.optionalMessage }}</span>
          </vf-textarea>
        </div>
      </vf-form-field>
      <slot name="formCta" :form="form" :size-attributes="sizeAttributes" />
    </div>
  </base-form>
</template>

<script lang="ts" setup>
import type { BaseForm as BaseFormType } from '#components'
import type { Product } from '#root/api/clients/product/data-contracts'
import type { UseFormattedPriceOptions } from '#types/composables/useFormattedPrice'
import type { AttributeSelection, GiftCardFormFields, ProductAttribute, ProductAttributeType } from '#types/product'

const props = defineProps<{
  product: Product
  pending: boolean
  error: boolean
}>()

const emit = defineEmits<{
  selectCardStyle: [id: string, url: string, optionLabel: string]
  selectCardAmount: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
  resetError: []
}>()

defineSlots<{
  formCta: (props: { form: GiftCardFormFields, sizeAttributes: ProductAttribute[] }) => void
}>()

const attributeSelection = defineModel<AttributeSelection>('attributeSelection', { required: true })

const form = defineModel<GiftCardFormFields>('form', {
  default: {
    fullName: '',
    email: '',
    message: '',
    policy: false,
    amount: '',
    size: ''
  }
})

const { messageMaxLength } = useAppConfig().components.form.giftCard || {}
const { validateEmail, validateMaxLength, validateName, validateRequired } = useLocalizedValidators()
const { $t } = useNuxtApp()

const formRef = ref<InstanceType<typeof BaseFormType>>()

const productColor = computed(() =>
  props.product?.attributes
    .find((attribute) => attribute.type === 'color')
    ?.options.find((color) => color.id === props.product.id)
)

const customAmountRange = computed(() => {
  const variant = props.product?.variants.find((variant) => variant.minDigitalProductAmount)
  return {
    minAmount: variant?.minDigitalProductAmount || 0,
    maxAmount: variant?.maxDigitalProductAmount || 0
  }
})

const extraCurrencyProps = { trailingZeroDisplay: 'stripIfInteger' } satisfies UseFormattedPriceOptions
const minAmount = useFormattedPrice(customAmountRange.value.minAmount, props.product?.currency, extraCurrencyProps)
const maxAmount = useFormattedPrice(customAmountRange.value.maxAmount, props.product?.currency, extraCurrencyProps)

const attributes = computed(() => {
  // Check if there is any attribute option that needs to be marked as unavailable according to its inventory
  return props.product?.attributes.map((attribute) => {
    // TEMP: mitigation against API not returning type field in attribute
    if ('code' in attribute) {
      // @ts-expect-error incorrect contract type
      attribute.type = attribute.code
      delete attribute.code
    }

    const options = attribute.options.filter(({ label }) => label !== 'X').map((option) => {
      // VariantIds are a list of all variant IDs
      const variantIds = props.product?.variants
        .filter((variant) => variant.attributes[attribute.type] === option.value)
        .map((variant) => variant.id)
      return attribute.label === 'Color'
        ? {
            ...option,
            available: option.id === props.product.id
              ? props.product?.variants.some(({ productInventoryState }) => productInventoryState === 'InStock')
              : option.available
          }
        : {
            ...option,
            label: useFormattedPrice(option.label, props.product?.currency, extraCurrencyProps),
            available: props.product?.variants.filter(({ id }) => variantIds?.includes(id))
              .some(({ productInventoryState }) => productInventoryState === 'InStock')
          }
    })

    return {
      groupBy: attribute.groupBy,
      groupedOptions: [{ options }],
      label: attribute.label === 'Color' ? $t.giftCardStyle : $t.giftCardAmount,
      options,
      type: attribute.type
    }
  }) || []
})

const attributeSelectionLabels = computed<Record<string, string>>(() => {
  const labels = {}
  attributes.value.forEach((attribute) => {
    labels[attribute.label]
      = attribute.type === 'color'
        ? productColor.value?.label
        : attribute.options.find((option) => option.value === attributeSelection[attribute.type])?.label
  })
  return labels
})

const sizeAttributes = computed(() => (attributes.value?.filter(({ type }) => type !== 'color') || []))

if (!attributeSelection.value.size) {
  const firstSize = sizeAttributes.value[0]?.options[0]

  emit('selectCardAmount', 'size', firstSize?.value, firstSize?.label)
}

const getAttrOptionAvailable = (type: ProductAttributeType, value: string) => {
  // Attribute types except a current type and 'color'
  const otherAttributeTypes = sizeAttributes.value.filter((attribute) => attribute.type !== type)
    .map((attribute) => attribute.type)
  if (otherAttributeTypes?.every((attributeType) => attributeSelection[attributeType])) {
    // If all other attribute types are selected, find the current option's variant
    const currentVariant = props.product.variants.find((variant) =>
      otherAttributeTypes.every((attributeType) =>
        variant.attributes[attributeType] === attributeSelection[attributeType])
        && variant.attributes[type] === value)

    return currentVariant?.productInventoryState === 'InStock'
  }
  else {
    // When the attribute option has other unselected attribute types, it's available
    return true
  }
}

// Required by the OTW override of the variant selection component
const productInventory = computed(() => ({
  variants: props.product.variants.reduce((inventory, variant) => ({
    ...inventory,
    [variant.id]: {
      quantity: 1
    }
  }), {})
}))

watch(() => form.value.amount, (value) => {
  const { options } = sizeAttributes.value[0] || {}

  if (value)
    attributeSelection.value.size = ''
  else if (!attributeSelection.value.size)
    attributeSelection.value.size = options[0]?.value || ''
})

watch(() => attributeSelection.value.size, (value) => {
  if (value) {
    form.value.amount = ''
    formRef.value?.reset()
  }
})

watch(() => Object.values(form.value), () => {
  if (props.error) {
    formRef.value?.reset()
    emit('resetError')
  }
})

onBeforeUnmount(() => {
  form.value.fullName = ''
  form.value.email = ''
  form.value.message = ''
  form.value.amount = ''
  form.value.size = ''
  form.value.policy = false
})

defineExpose({
  validate: (force?: boolean) => formRef.value?.validate(force),
  reset: () => formRef.value?.reset()
})
</script>
