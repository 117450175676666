<template>
  <base-picture
    :src="getImageTransformations(items?.[0]?.src, mediaSize) || defaultImageUrl"
    :alt="items?.[0]?.alt || name"
    :width="mediaSize.width"
    :height="mediaSize.height"
    :lazy
    class="w-full cursor-pointer"
    :props-img="{ class: 'w-a', fetchpriority }"
    data-test-id="product-card-pic"
    @mouseenter="activeCarousel = $viewport.lg"
    @click="$emit('click-item', { type: 'image', i: 0 })"
  />
  <transition v-if="items?.[1]?.src" name="fade">
    <template v-if="showSecondaryMedia || activeCarousel">
      <lazy-product-card-carousel
        v-if="$feature.enableProductCardGalleryCarousel"
        :items
        :lazy
        :name
        @click-item="$emit('click-item', $event)"
      />
      <component
        :is="items[1].type === 'image' ? BasePicture : BaseVideo"
        v-else
        class="absolute-0 full contain"
        :alt="items[1].alt || name"
        :src="items[1].type === 'image' ? getImageTransformations(items[1].src, mediaSize) : items[1].src"
        :width="mediaSize.width"
        :height="mediaSize.height"
        :lazy
        loop
        autoplay
        muted
        data-test-id="product-card-pic-hover"
      />
    </template>
  </transition>
</template>

<script lang="ts" setup>
import type { ImgHTMLAttributes } from '#types/components/base/picture'
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import { BasePicture, BaseVideo } from '#components'

defineProps<{
  items?: ProductGalleryExtended
  lazy?: boolean
  // product Name, used as alt fallback
  name: string
  showSecondaryMedia?: boolean
}>()

defineEmits<{
  'click-item': [payload: { type: 'image' | 'video', i: number }]
}>()

const { card: { mediaSize }, defaultImageUrl } = useAppConfig().components.product
const activeCarousel = ref(false)
const { fetchpriority } = useAttrs() as ImgHTMLAttributes
</script>
