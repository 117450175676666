<template>
  <base-form
    ref="formRef"
    :form="form"
    validate-on="submit"
    class="space-y-6"
    data-test-id="form-filter-price"
    @submit="handleSubmit"
  >
    <div class="space-y-1" data-test-id="vf-form-field-max">
      <div class="flex gap-4">
        <vf-input>
          <span>{{ $t.min }}</span>
          <template #input>
            <div class="flex" :class="{ 'flex-row-reverse': !isCurrencyOnLeft }">
              <span v-if="form.min.length">
                {{ currencySymbol }}
              </span>
              <base-input
                v-model="form.min"
                type="text"
                :class="{ [baseInputClasses]: form.min.length }"
                @focus="handleFocus"
                @input="handleInput"
                @keydown="handleKeyDown"
              />
            </div>
          </template>
        </vf-input>
        <base-form-field v-slot="{ attrs }" name="max" :rule="priceValidation">
          <vf-input :class="{ '!b-red-30': attrs.invalid }">
            <span :class="{ '!c-red-30': attrs.invalid }">{{ $t.max }}</span>
            <template #input>
              <div class="flex" :class="{ 'flex-row-reverse': !isCurrencyOnLeft }">
                <span v-if="form.max.length">
                  {{ currencySymbol }}
                </span>
                <base-input
                  v-model="form.max"
                  type="text"
                  :class="{ [baseInputClasses]: form.max.length }"
                  @focus="handleFocus"
                  @input="handleInput"
                  @keydown="handleKeyDown"
                />
              </div>
            </template>
          </vf-input>
        </base-form-field>
      </div>
      <vf-form-error v-if="formRef?.fields.max.message" data-test-id="vf-form-error-max">
        {{ formRef.fields.max.message }}
      </vf-form-error>
    </div>
    <div class="flex items-center gap-4">
      <vf-button :variant="applyButtonVariant" size="tiny" type="submit" style="max-width: 8.125rem;">
        {{ $t.applyPrice }}
      </vf-button>
      <base-button class="text-sm underlined" @click="handleReset">
        {{ $t.reset }}
      </base-button>
    </div>
  </base-form>
</template>

<script lang="ts" setup>
import type { BaseForm as BaseFormType } from '#components'
import type { Validator } from '#core/utils/validators'
import type { PriceFilter } from '#types/filters'

const props = defineProps<{
  filter: PriceFilter
  loading?: boolean
  currency: string
}>()

const { applyButtonVariant } = useAppConfig().components.filter.option.priceInput

const currencySymbol = getCurrencySymbol(useLocale(), props.currency)
const isCurrencyOnLeft = useFormattedPrice(0, props.currency).toString().startsWith(currencySymbol)
const model = defineModel<string[]>({ default: [] })

const { $t, $viewport } = useNuxtApp()

const formRef = ref<InstanceType<typeof BaseFormType>>()
const form = reactive({
  min: model.value[0] ?? '',
  max: model.value[1] ?? ''
})

/**
 * Validates that `max` is required. `min` is not required and will default to 0.
 * If value of `max` is less than `min`, throw an error message.
 */
const priceValidation: Record<string, Validator> = {
  max: (_, data) => !data?.max && ($t.pleaseEnterAValueAbove),
  less: (_, data) => {
    const _min = Number.parseFloat(data?.min || 0)
    const _max = Number.parseFloat(data?.max || 0)
    const formattedMin = isCurrencyOnLeft ? `${currencySymbol}${_min}` : `${_min}${currencySymbol}`
    return _max <= _min && replaceAll($t.pleaseEnterAValueAboveMin, { _min: formattedMin })
  }
}

/**
 * Scrolls the price filter input into view after the virtual keyboard expandeds on mobile devices.
 */
const handleFocus = async (event: { target: HTMLInputElement }) => {
  if (!$viewport.lg) {
    setTimeout(() => {
      const closest = event.target?.closest<HTMLDivElement>('[data-scroll-el="filtersPanel"]')
      if (!closest) return
      const panelScroller = closest.parentElement
      const pillsSection = panelScroller?.querySelector<HTMLDivElement>('section.sticky')
      const filterAccordion = event.target?.closest<HTMLDivElement>('[data-scroll-el="filtersPanelAccordion"]')
      if (filterAccordion && pillsSection) {
        const nodeScrollY = filterAccordion.offsetTop - pillsSection.offsetHeight
        panelScroller?.scrollTo({ top: nodeScrollY })
      }
    }, 200)
  }
}

/**
 * Prevents non-number characters from being input on Android devices.
 */
const handleInput = (event: { data: string, target: HTMLInputElement }) => {
  if (/\D/.test(`${event.data}`)) {
    event.target.value = event.target.value.replace(/\D/g, '')
    form.min = `${form.min}`.replace(/\D/g, '')
    form.max = `${form.max}`.replace(/\D/g, '')
  }
}

/**
 * Price filter inputs only allow numbers. Using KeyboardEvent.key since KeyboardEvent.keyCode and KeyboardEvent.which are deprecated.
 */
const handleKeyDown = (event: KeyboardEvent) => {
  if (/\D/.test(event.key) && ![
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'Backspace',
    'Delete',
    'Enter',
    'Escape',
    'NumLock',
    'Tab'
  ].includes(event.key))
    event.preventDefault()
}

const handleReset = () => {
  form.min = ''
  form.max = ''
  formRef.value?.reset()
  model.value = []
}

const handleSubmit = () => {
  form.min = form.min || '0'
  model.value = [form.min, form.max]
}

const baseInputClasses = computed(() => isCurrencyOnLeft ? 'pl-1' : 'text-right pr-1')

/**
 * Defaults form.min to 0 if form.max exists.
 */
watch(() => form.max, () => {
  form.min = !form.min && form.max ? '0' : form.min
})
</script>
