<template>
  <vf-carousel
    v-if="isCarousel"
    class-container="relative"
    class-controls="top-5 mt-2 mx-2 "
    size-controls="md"
    loop
    @init="({ activeItem }) => pushUpsellImpression(activeItem)"
    @to-prev="({ activeItem }) => pushUpsellImpression(activeItem)"
    @to-next="({ activeItem }) => pushUpsellImpression(activeItem)"
  >
    <product-upsell
      v-for="(upsell, i) in upsellsWithInventory"
      :key="i"
      :model-value="modelValue"
      v-bind="{ upsell, showTitle, showModal, showCheckbox, showButton, showDetails }"
      class-title="ml-8"
      data-test-id="product-upsell"
      @update:model-value="$emit('update:modelValue', $event)"
      @rec-click="$emit('recClick', $event)"
    />
  </vf-carousel>
  <div v-else>
    <div v-for="(upsell, i) in upsellsWithInventory" :key="i" data-test-id="product-upsell">
      <product-upsell
        :model-value="modelValue"
        v-bind="{ upsell, showTitle, showModal, showCheckbox, showButton, showDetails }"
        :class="{ 'mt-3': i }"
        @update:model-value="$emit('update:modelValue', $event)"
        @rec-click="$emit('recClick', $event)"
      />
      <hr
        v-if="i !== (upsellsWithInventory.length - 1)"
        class="m-a b-grey-50 bg-grey-50"
        style="max-width: 98%"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: string[]
  showModal?: boolean
  showTitle?: boolean
  showButton?: boolean
  showCheckbox?: boolean
  showDetails?: boolean
  upsells: any[]
  isCarousel?: boolean
  skipCheckInventory?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [payload: string[]]
  'recClick': [payload: string]
}>()

const { $gtm } = useNuxtApp()

const inventory = props.skipCheckInventory
  ? []
  : await Promise.all(
    props.upsells?.map(({ id }) => useApi().products.$inventory(id))
  )

const upsellsWithInventory = computed(() => {
  if (props.skipCheckInventory || !inventory.length) return props.upsells
  const upsells = props.upsells.map((upsell, i) => {
    const { quantity = 0 } = inventory[i].variants[upsell.sku] || {}
    return {
      ...upsell,
      productInventoryState: quantity > 0 ? 'InStock' : 'OutOfStock'
    }
  })

  return upsells.filter(({ productInventoryState }) => productInventoryState === 'InStock')
})

const pushUpsellImpression = (index: number) => $gtm.push('upsell.onUpsellImpression', upsellsWithInventory.value[index].id)

onUnmounted(() => {
  emit('update:modelValue', [])

  !props.isCarousel && upsellsWithInventory.value.forEach((_, i) => pushUpsellImpression(i))
})
</script>
