<template>
  <div class="mx-a md:min-w-72 md:w-max space-y-4">
    <p class="text-center text-sm" aria-live="polite">
      {{ replaceAll($t.viewingProducts, { current, total }) }}
    </p>
    <vf-progress-bar :value="current" :max="total" class="mx-a" />
    <vf-button v-if="current < total" :size="buttonSize" class="w-full" @click="$emit('load-more')">
      {{ $t.viewMore }}
      <span v-if="pageSize">&nbsp;({{ pageSize }})</span>
    </vf-button>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Current number of products shown
   */
  current: number
  /**
   * Total number of products
   */
  total: number
  /**
   * Number of products in next page
   */
  pageSize?: number
}>()

defineEmits<{
  'load-more': []
}>()

const { buttonSize } = useAppConfig().components.product.pagination
</script>
