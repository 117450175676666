<template>
  <div class="relative gap-2" :class="[layout, layout === 'grid' ? 'cols-3' : 'wrap']">
    <base-toggle
      v-for="({ id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      class="cursor-pointer"
      data-test-id="vf-style-filter"
    >
      <span
        class="f-col justify-center gap-1 b b-grey-40 rounded-sm p-2 text-center text-xs c-grey-10 duration peer-focus-visible:outline-auto"
        :class="{
          'b-transparent bg-brand-1 ':
            model?.includes?.(id),
        }"
        :style="{ height, width }"
        data-test-id="vf-style-filter"
      >
        <base-picture
          :src="`/img/tile-images/${id?.toLowerCase().split(' ').join('-')}.svg`"
          :alt="label"
          height="56"
          width="56"
          class="aspect-square"
        />
        <span class="truncate" data-test-id="vf-style-filter-text">
          {{ label }}
        </span>
      </span>
    </base-toggle>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { StyleFilter } from '#types/filters'

withDefaults(
  defineProps<{
    filter: StyleFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const { height, width } = useAppConfig().components.filter.option.tileImage

const model = defineModel<string[]>()
</script>
