<template>
  <div class="w-full text-center" :data-test-id="`ugc-review-${review.reviewId}`">
    <div class="inline-grid gap-1">
      <base-picture
        v-if="review.media && review.media[0].type === 'image'"
        :src="review.media[0].uri"
        width="400"
        height="400"
        :props-img="{ class: 'w-a h-a max-h-56 max-w-56 md:max-h-100 md:max-w-100 mx-a' }"
      />
      <base-video
        v-if="review.media && review.media[0].type === 'video'"
        class="mx-a aspect-square h-a max-w-56 w-a md:max-h-100 md:max-w-100"
        :src="review.media[0].uri"
        controls
      />
      <div v-if="isCarousel" class="text-left text-sm space-y-1">
        <div v-if="review?.media?.[0].rating" class="flex gap-1">
          <vf-rating :value="review.media[0].rating" />
          <span class="text-xs c-grey-20">
            ({{ review.media[0].rating }})
          </span>
        </div>
        <div class="subtitle-5 pr-1">
          {{ review.details.nickname }}
        </div>
        <div v-if="review.media" class="c-grey-20">
          {{ review?.media[0].headline }}
        </div>
      </div>
    </div>
    <div class="mt-6 flex items-center b-t pt-4 text-sm">
      <base-button
        class="flex items-center gap-1"
        :aria-label="$t.reviewVoteHelpful"
        @click="handleVote({ voteType: 'helpful' })"
      >
        <vf-icon name="thumbs-up" size="sm" />
        {{ Math.min(helpfulVotes, 99) }}
      </base-button>
      <base-button
        class="ml-2 flex items-center gap-1"
        :aria-label="$t.reviewVoteNotHelpful"
        @click="handleVote({ voteType: 'unhelpful' })"
      >
        <vf-icon name="thumbs-up" size="sm" dir="left" />
        {{ Math.min(notHelpfulVotes, 99) }}
      </base-button>
      <vf-link
        v-if="!flagged"
        class="ml-4 text-xs"
        @click="ModalReportIssue.open({ reviewId: review.reviewId }).then(() => flagged = true)"
      >
        {{ $t.reviewFlagCTA }}
      </vf-link>
      <p v-else class="ml-4 text-xs nowrap">
        {{ $t.youFlaggedReview }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ProductReviewsReview, ProductReviewsVoteRequest } from '#root/api/clients/product/data-contracts'

const { productId, review } = defineProps<{
  productId: string
  review: ProductReviewsReview
  isCarousel: boolean
}>()

const { ModalReportIssue } = useDialogsStore()

const hasVoted = ref(false)
const helpfulVotes = ref(review.metrics.helpfulVotes)
const notHelpfulVotes = ref(review.metrics.notHelpfulVotes)
const flagged = ref(false)

function handleVote({ voteType }: ProductReviewsVoteRequest) {
  if (hasVoted.value) return

  useApi().products.reviewVote(productId, review.reviewId, { voteType })
  voteType === 'helpful' ? helpfulVotes.value++ : notHelpfulVotes.value++
  hasVoted.value = true
}
</script>
