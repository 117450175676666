<template>
  <vf-carousel
    ref="gallery"
    class="group !absolute-0"
    class-controls="op-0 group-hover:op-100 duration"
    data-test-id="product-card-gallery"
    loop
    @init="onInit"
  >
    <div
      v-for="({ alt, type, src, meta }, key) in _items"
      :key
      class="relative w-full"
      :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }"
    >
      <component
        :is="type === 'image' ? BasePicture : BaseVideo"
        ref="media"
        class="absolute-0 full cursor-pointer contain"
        :src
        :width="mediaSize.width"
        :height="mediaSize.height"
        :poster="meta?.poster"
        :alt
        :meta
        :lazy
        loop
        muted
        @click="$emit('click-item', { type, i: key })"
      />
      <base-button
        v-if="type === 'video'"
        class="absolute right-0 mr-2 rounded-full bg-white p-2 shadow-sm"
        :class="badgePosition === 'bottom' ? 'top-0 mt-2' : 'bottom-0 mb-2'"
        @click="media?.[key].toggle"
      >
        <vf-icon :name="!media?.[key].paused ? 'pause' : 'play'" size="lg" />
      </base-button>
    </div>
  </vf-carousel>
</template>

<script lang="ts" setup>
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import { BasePicture, BaseVideo, VfCarousel } from '#components'

const props = defineProps<{
  items: ProductGalleryExtended
  lazy?: boolean
  // product Name, used as alt fallback
  name: string
}>()

defineEmits<{
  'click-item': [payload: { type: 'image' | 'video', i: number }]
}>()

const { badgePosition, maxGalleryItems, mediaSize } = useAppConfig().components.product.card
const _items = computed(() => props.items.slice(0, maxGalleryItems).map((item) => ({
  ...item,
  alt: item.alt || props.name,
  src: item.type === 'image' ? getImageTransformations(item.src, mediaSize) : item.src,
  meta: item.type === 'video' ? item.meta : undefined
})))
const media = ref<(typeof BasePicture | typeof BaseVideo)[]>()
const gallery = ref<typeof VfCarousel>()

const onInit = () => {
  watch(() => gallery.value?.scroll?.activeItem, (current, previous) => {
    if (media.value?.[previous]?.pause && !media.value[previous].paused.value)
      media.value[previous].pause()
    media.value?.[current]?.play?.()
  })
}
</script>
