<template>
  <div class="h-8 skeleton" />
  <div class="flex gap-x-3 gap-y-4 pb-6 pt-2 wrap">
    <div v-for="i in 6" :key="i" class="w-16 children:skeleton items-center space-y-1">
      <div class="h-8 w-8" />
      <div class="h-4" style="width: 2.25rem" />
    </div>
  </div>
  <div class="h-8 skeleton" />
  <div class="mb-5 mt-2 flex gap-1 wrap">
    <div v-for="i in 6" :key="i" class="w-18 skeleton" style="height: 6rem" />
  </div>
  <div class="h-8 skeleton" />
  <div class="flex gap-2 pb-6 pt-2 wrap">
    <div v-for="i in 8" :key="i" class="h-10 w-10 skeleton" />
  </div>
  <div class="h-8 skeleton" />
  <div class="flex gap-x-2 gap-y-4 pb-6 pt-2 wrap">
    <div v-for="i in 4" :key="i" class="h-10 w-18 skeleton" />
  </div>
  <div class="h-8 skeleton" />
  <div class="pb-6 pt-2 space-y-4">
    <div v-for="i in 7" :key="i" class="flex children:skeleton items-center gap-x-2">
      <div class="h-6 w-6" />
      <div class="h-4 w-32" />
    </div>
  </div>
  <div class="h-8 skeleton" />
  <div class="ml-4">
    <div class="mb-4 mt-2 flex children:skeleton" style="column-gap: 4.5rem;">
      <div class="h-8 w-15" />
      <div class="h-8 w-15" />
    </div>
    <div class="h-8 w-48 skeleton" />
    <div class="mb-4 mt-2 flex children:skeleton items-center gap-x-6">
      <div class="h-8 w-25" />
      <div class="h-4 w-10" />
    </div>
  </div>
  <div v-for="i in 3" :key="i" class="h-8 skeleton" />
</template>
