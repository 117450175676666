<template>
  <vf-button
    v-bind="{ loading, variant, size, style }"
    :aria-label="$t.applePayIconText"
    :class="brandClasses?.button"
  >
    <vf-icon src="/img/logos/apple-pay.svg" :class="brandClasses?.icon" />
  </vf-button>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Indicates loading state
   */
  loading?: boolean
  style?: string
}>()

const { variant, size, brandClasses } = useAppConfig().components.checkout.applePayButton
</script>
