export default () => {
  const { excludeStickyRoute, showOnScrollUp } = useAppConfig().components.vf.header || {}
  const router = useRouter()

  const pageScrolledUp = ref(false)

  if (showOnScrollUp) {
    const { directions } = useScroll(document)
    const { bottom, top } = toRefs(directions)

    watchDebounced(top, (value) => {
      if (value) pageScrolledUp.value = true
    }, { debounce: 200, maxWait: 500 })

    watchDebounced(bottom, (value) => {
      if (value) pageScrolledUp.value = false
    }, { debounce: 200, maxWait: 500 })
  }

  const isStickyHeaderEnabled = computed(
    () => !excludeStickyRoute.some((prefix) => router.currentRoute.value.name?.toString().startsWith(prefix))
  )

  const stickyHeaderMargin = computed(
    () => isStickyHeaderEnabled.value ? useElementBounding(useState('headerRef')).bottom.value - 1 || 0 : 0
  )

  const stickyHeaderOffset = computed(() => `top: ${pageScrolledUp.value ? stickyHeaderMargin.value : 0}px`)

  return {
    isStickyHeaderEnabled,
    pageScrolledUp,
    stickyHeaderMargin,
    stickyHeaderOffset
  }
}
