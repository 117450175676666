<template>
  <article class="space-y-2" :data-test-id="`customer-question-${question.question_id}`">
    <div class="lg:flex lg:flex-row-reverse lg:gap-4 <lg:space-y-2">
      <!-- Question Headline -->
      <p class="!subtitle-8 title-4 lg:grow" :data-test-id="`customer-question-headline-${question.question_id}`">
        {{ question.details.text }}
      </p>

      <!-- Asked By -->
      <p class="text-xs lg:h-6 lg:w-40 lg:shrink-0" :data-test-id="`customer-question-asked-by-${question.question_id}`">
        {{ $t.askedBy }} {{ question.details.nickname }} {{ useTimeSince(answer.details.created_date) }}
      </p>
    </div>

    <!-- Answer Details -->
    <div class="lg:ml-42" :data-test-id="`customer-answer-${answer.answer_id}`">
      <!-- Answer Body -->
      <div class="space-y-2">
        <div v-if="answer.details.text" class="bg-grey-90 px-2 py-4 space-y-2 ">
          <div v-if="answer.details.is_verified || answer.details.is_expert" class="flex">
            <vf-icon name="success" size="sm" class="c-green-30" />
            <p class="ml-1">
              {{ $t.verifiedReply }} - {{ answer.details.nickname }}
            </p>
          </div>
          <p class="mb-2">
            <base-interpolator :content="answer.details.text.replace(RegExp(searchTerm!, 'gi'), '{bold|$&}')">
              <template #bold="{ args: [message] }">
                <b>{{ message }}</b>
              </template>
            </base-interpolator>
          </p>
        </div>
        <div class="flex gap-x-6 gap-y-2 pt-2 <md:f-col" :data-test-id="`answer-helpful-section-${question.question_id}`">
          <strong>{{ $t.answerVoteCTA }}</strong>
          <div class="flex items-center gap-2">
            <base-button
              class="flex items-center gap-1"
              :aria-label="$t.reviewVoteHelpful"
              @click="handleVote({ voteType: 'helpful', ugc_id: answer.ugc_id })"
            >
              <vf-icon name="thumbs-up" size="md" />
              {{ Math.min(helpfulVotes, 99) }}
            </base-button>
            <base-button
              class="flex items-center gap-1"
              :aria-label="$t.reviewVoteNotHelpful"
              @click="handleVote({ voteType: 'unhelpful', ugc_id: answer.ugc_id })"
            >
              <vf-icon name="thumbs-up" size="md" dir="left" />
              {{ Math.min(notHelpfulVotes, 99) }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { ProductAnswersVoteRequest, ProductReviewsQuestions } from '#root/api/clients/product/data-contracts'

const { productId, question, searchTerm } = defineProps<{
  productId: string
  question: ProductReviewsQuestions
  searchTerm?: string
}>()

const answer = computed(() => question.answer[0])

const hasVoted = ref(false)
const helpfulVotes = ref(answer.value.metrics?.helpful_votes)
const notHelpfulVotes = ref(answer.value.metrics?.not_helpful_votes)

function handleVote({ voteType, ugc_id }: ProductAnswersVoteRequest) {
  if (!hasVoted.value) {
    useApi().products.answerVote(productId, answer.value.answer_id, { voteType, ugc_id })
    voteType === 'helpful' ? helpfulVotes.value++ : notHelpfulVotes.value++
    hasVoted.value = true
  }
}
</script>
