<template>
  <div class="f-col gap-4 b-t b-grey-70 pt-4 ">
    <section
      v-for="({ groupBy, groupedOptions, label, type }, i) in variantAttributes"
      :key="type"
            :data-test-id="`${type}-groups`"
      :data-scroll-el="`${type}Pickers`"
    >
      <div
        v-if="!(isCustomsProduct && type === 'color')"
        :class="{ 'flex items-center space-x-2': showBadge }"
      >
        <div v-if="showBadge" class="h-4 w-4 flex justify-center rounded-full bg-grey-10">
          <span class="text-xs c-white fw-bold">{{ i + 1 }}</span>
        </div>
        <div :id="label" class="flex text-sm ">
          <h2 class="fw-bold">
            {{ label }}:
          </h2>
          <p class="pl-1 ">
            {{ attributeSelectionLabels[type] }}
          </p>
        </div>
      </div>
      <vf-form-error v-if="type !== 'color' && error && !attributeSelection[type]" class="mt-1">
        {{ product.productType === 'Digital' ? $t.selectCardAmountError : $t.selectSizeError }}
      </vf-form-error>
      <template v-if="!(isCustomsProduct && type === 'color')">
        <div
          v-for="({ groupValue, options }, j) in groupedOptions"
          :key="j"
          class="mt-2"
          :class="{ 'mb-4': j !== groupedOptions.length - 1 }"
          :data-test-id="`${type}-groups-item`"
        >
          <product-pricing
            v-if="groupValue && groupedOptions.length > 1 && groupBy === 'price'"
            :price="groupValue"
            :currency="product.currency"
            class="mb-2"
          />
          <component
            :is="options.length > swatches.collapseThreshold ? BaseCollapse : 'div'"
            v-if="type === 'color'"
            v-bind="options.length > swatches.collapseThreshold && {
              classToggle: 'self-end ',
              classContent: isRectangle ? 'max-h-48 ' : 'max-h-54 lg:max-h-75',
              disableVisibilityToggle: true,
              inverted: true,
            }"
          >
            <fieldset :aria-labelledby="label" class="flex wrap" :class="{ 'gap-1 ': isRectangle }">
              <vf-color-picker
                v-for="{ available, id, label: optionLabel, swatch, url, value } in options"
                :key="optionLabel"
                :color="swatch?.color"
                :thumbnail="swatch?.image"
                :active="!pending && getIsActive(value, id)"
                :loading="getIsPending(value, id, pending)"
                :size="swatches.size"
                :variant="colorPickerShape"
                :unavailable="!available && !pending"
                :name="optionLabel"
                @click="selectNewColor(enableColorAsAttribute ? value : id, url, optionLabel)"
              />
            </fieldset>
            <!-- toggle for base-collapse -->
            <template #toggle="{ expanded }">
              <div >
                <p class="py-2 text-right text-sm lh-3 underlined lg:py-4">
                  {{ expanded ? $t.viewLess : $t.viewMore }}
                </p>
                <vf-icon
                  v-if="swatches.showCollapseIcon"
                  name="chevron"
                  size="md"
                  class="ml-1"
                  :dir="expanded ? 'up' : 'down'"
                />
              </div>
            </template>
          </component>
          <fieldset v-else :aria-labelledby="label">
            <base-form-field v-slot="{ attrs }" :name="type" :rule="validateRequired()">
              <div class="flex gap-2 wrap" :class="{ '': type === 'size' }">
                <vf-size-picker
                  v-for="({ available, label: optionLabel, value }, n) in options"
                  :key="optionLabel"
                  :type
                  :model-value="attributeSelection[type]"
                  :value
                  :name="label"
                  :required="n === 0"
                  :unavailable="!pending && (!available || !getAttrOptionAvailable(type, value))"
                  v-bind="attrs"
                  @input="$emit('selectSize', type, value, optionLabel)"
                >
                  {{ optionLabel }}
                </vf-size-picker>
              </div>
            </base-form-field>
          </fieldset>
        </div>
      </template>
      <vf-link
        v-if="$feature.showCustomizeOnPDP && product.customsExternalurl && type === 'color'"
        :to="product.customsExternalurl"
        class="items-center gap-1 text-sm fw-bold lh-3 i-flex hover:c-brand-3"
        :class="{ 'mt-4': !isCustomsProduct }"
      >
        <vf-icon name="customs" size="md" /> {{ $t.customize }}
      </vf-link>
      <div v-if="isComingSoon" class="mt-4 space-y-4 ">
        <p class="title-4 c-grey-20 ">
          {{ comingSoonText }}
        </p>
        <p v-if="product.notifyMe">
          {{ replaceAll($t.comingSoonDescription, { productName: product.name }) }}
        </p>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { formatDate } from '@vueuse/core'
import { BaseCollapse } from '#components'
import type { Product } from '#root/api/clients/product/data-contracts'
import type { AttributeSelection, ColorSwatchShape, OptionGroups, ProductAttribute, ProductAttributeType } from '#types/product'

const props = defineProps<{
  attributes: (ProductAttribute & { groupedOptions: OptionGroups[] })[]
  attributeSelectionLabels: Record<string, string>
  error?: boolean
  pending?: boolean
  getAttrOptionAvailable: (type: ProductAttributeType, value: string) => boolean
  product: Product
  isComingSoon?: boolean
  showSizeAndFit?: boolean
  colorSwatch?: ColorSwatchShape
  showBadge?: boolean
  /*
  * These props are added only for consistency with other brands
  * They aren't used in the component
  * but allows to avoid situations with incorrect props resolution when rendering the DOM
  */
  allVariantsOutOfStock?: any
  productInventory?: any
  showNotifyMe?: any
  variants?: any
}>()

const emit = defineEmits<{
  selectColor: [id: string, url: string, optionLabel: string]
  selectSize: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
}>()

const { enableColorAsAttribute, swatches } = useAppConfig().pages.pdp || {}
const { $feature, $t } = useNuxtApp()

const colorPickerShape = props.colorSwatch ?? swatches.variant
const isRectangle = colorPickerShape === 'rectangle'
const attributeSelection = defineModel<AttributeSelection>({ required: true })

const selectedProductId = ref()

const variantAttributes = computed(() =>
  props.isComingSoon ? props.attributes.filter((attribute) => attribute.type === 'color') : props.attributes)

const selectNewColor = (id, url, optionLabel) => {
  selectedProductId.value = id
  emit('selectColor', id, url, optionLabel)
}

const getIsActive = (value: string, id: string) =>
  enableColorAsAttribute ? attributeSelection.value.color === value : props.product.id === id

const getIsPending = (value: string, id: string, pending?: boolean) => {
  if (!pending) return

  return enableColorAsAttribute
    ? attributeSelection.value.color === value
    : selectedProductId.value === id
}

const isCustomsProduct = computed(() =>
  props.attributes.find(({ type }) => type === 'color')?.options?.some(({ label }) => label === 'Customs')
)

const comingSoonText = computed(() => {
  return props.product.targetPublishDate
    ? `${$t.comingSoon} - ${
      formatDate(new Date(props.product.targetPublishDate.replace(/-/g, '\/')), $feature.configProductComingSoonDateFormat)}`
    : $t.comingSoon
})
</script>
