<template>
  <span
    class="h-8 items-center align-top i-flex "
    :class="{ '': !icon }"
  >
    <template v-if="icon">
      <img v-if="badges[icon]" :src="badges[icon]" class="h-6" aria-hidden="true" alt="">
      <vf-icon v-else :name="icon" :size="iconSize" />
    </template>
    <span
      v-if="label"
      class="text-xs c-grey-20 uppercase "
      :class="{ 'pl-1': icon }"
    >
      {{ label }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { filename } from 'pathe/utils'
import type { Badge } from '#types/product'

const { icon } = defineProps<Badge>()
const { iconSize } = useAppConfig().components.product.badge
const glob = import.meta.glob<string>('@brand/assets/badges/*.svg', { eager: true, import: 'default' })
const badges = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value]))

if (icon && !badges[icon]) {
  log.error(`
    Product/Badge: There is no local file present for ${icon}.
    No icon will be displayed.
    Please, check if your configuration is correct`)
}
</script>
